const baseUrl = '/static';
const getJsonEndpoint = (endpoint) => {
    return baseUrl + '/json/' + endpoint + '.json?v=1.3';
};
const getImageEndpoint = (endpoint, fileType = 'png') => {
    return baseUrl + '/images/' + endpoint + '.' + fileType + '?v=1.2';
};
const fetchJson = (endpoint) => {
    return fetch(getJsonEndpoint(endpoint));
};

class DataHandler {
    constructor() {
        this.initialized = {};
        this.data = {};
    }

    /**
     * @returns {[{}]} List of franchise info objects
     */
    listFranchises() {
        return this.data['franchises'].map((franchise, i) => {
            franchise.id = i;
            franchise.path = franchise.name;
            return franchise;
        });
    }

    /**
     * @param {string} franchisePath The data path of the franchise
     * @returns {{}} The franchise info object
     */
    getFranchiseInfo(franchisePath) {
        return this.listFranchises().find((f) => f.name === franchisePath);
    }

    /**
     * @param {string} franchisePath The data path of the franchise
     * @returns {[{}]} List of group info objects
     */
    listGroups(franchisePath) {
        return this.getFranchiseInfo(franchisePath).groups.map((group, i) => {
            group.id = i;
            group.franchisePath = franchisePath;
            group.path = franchisePath + '/' + group.name;
            return group;
        });
    }

    /**
     * @param {string} groupPath The data path of the group
     * @returns {{}} The group info object
     */
    getGroupInfo(groupPath) {
        let spl = groupPath.split('/');
        let franchiseName = spl[0];
        let groupName = spl[1];
        return this.listGroups(franchiseName).find((g) => g.name === groupName);
    }

    /**
     * @param {string} groupPath The data path of the group
     * @returns {[{}]} The list of member objects
     */
    listMembers(groupPath) {
        return this.getGroupInfo(groupPath).members.map((member, i) => {
            member.id = i;
            member.franchisePath = groupPath.split('/')[0];
            member.groupPath = groupPath;
            member.path = groupPath + '/' + member.name;
            return member;
        });
    }

    /**
     * @param {string} memberPath The data path of the member
     * @returns {{}} The member info object
     */
    getMemberInfo(memberPath) {
        let spl = memberPath.split('/');
        let groupPath = spl[0] + '/' + spl[1];
        let memberName = spl[2];
        return this.listMembers(groupPath).find((member) => member.name === memberName);
    }

    /**
     * @param {string} memberPath The data path of the member
     * @param {('thumb'|'full')} imageType The image type to get
     * @returns {string} The URL to the image
     */
    getMemberImage(memberPath, imageType) {
        return getImageEndpoint(`member/${imageType}/${memberPath}`);
    }

    /**
     * @param {string} memberPath The data path of the member
     * @returns {{}} The object containing all the colors
     */
    getMemberColors(memberPath) {
        return this.getMemberInfo(memberPath).colors;
    }

    /**
     * @param {string} memberPath The data path of the member
     * @param {('main'|'light'|'dark')} colorType The hue of color to get
     * @returns {string} The hex value of the color
     */
    getMemberColor(memberPath, colorType) {
        return this.getMemberColors(memberPath)[colorType];
    }

    /**
     * @param {number} franchiseId The ID of the franchise
     * @returns {string} The absolute path of the franchise
     */
    getFranchisePathFromId(franchiseId) {
        return this.listFranchises().find((f) => f.id === franchiseId).path;
    }

    /**
     * @param {string} franchisePath The path of the franchise
     * @param {number} groupId The ID of the group
     * @returns {string} The absolute path of the group
     */
    getGroupPathFromId(franchisePath, groupId) {
        return this.listGroups(franchisePath).find((g) => g.id === groupId).path;
    }

    /**
     * @param {string} groupPath The path of the group
     * @param {number} memberId The ID of the member
     * @returns {string} The absolute path of the member
     */
    getMemberPathFromId(groupPath, memberId) {
        return this.listMembers(groupPath).find((m) => m.id === memberId).path;
    }

    /**
     * @returns {[{}]} List of SIFAS card info objects
     */
    listASCards() {
        var member_info = this.data['card_info'];
        return Object.keys(member_info).map((m_id) => {
            var member = this.data['card_info'][m_id];
            member.id = m_id;
            return member;
        });
    }

    /**
     * @returns {{}} The SIFAS member with the given ID
     */
    getASCard(id) {
        return this.listASCards().find((m) => m.id === id);
    }

    /**
     * @param {*} id the ID of the member
     * @param {boolean} idolized true to get idolized icon, false to get unidolized (defaults to false)
     * @returns {string} The URL of the icon
     */
    getASCardIconPath(id, idolized = false) {
        return getImageEndpoint(`${idolized ? 'icons_idolized' : 'icons_unidolized'}/${id}`);
    }

    /**
     * @param {*} id the member ID
     * @param {string} stat the stat to get
     * @param {number} lb the limit break value
     * @param {number} lvl the level
     */
    getASCardStat(id, stat, lb = 0, lvl = -1) {
        var member = this.getASCard(id);
        if (lvl < 0) {
            lvl = member.rarity === 'ur' ? 80 : member.rarity === 'sr' ? 60 : 40;
        }
        return member.level[lvl.toString()][stat] + member.training[lb.toString()][stat] + member.awaken[stat];
    }

    /**
     * @param {string} icon The icon to get
     * @returns {string} The URL to the icon
     */
    getASIconPath(icon) {
        return getImageEndpoint(`icons_builder/icon_${icon}`);
    }

    listASMemberIDs() {
        var ret = [];
        for (let i = 0; i <= 2; i++) {
            for (let j = 1; j <= 9; j++) {
                ret.push(i * 100 + j);
            }
        }
        return ret;
    }

    getASMemberID(memberPath) {
        var member = this.getMemberInfo(memberPath);
        if (member.groupPath === 'lovelive/muse') {
            return member.id + 1;
        }
        if (member.groupPath === 'lovelive/aqours') {
            return 101 + member.id;
        }
        if (member.groupPath === 'lovelive/nijigaku') {
            return 201 + member.id;
        }
        return null;
    }

    getMemberFromASID(memberId) {
        if (memberId > 200) {
            return this.listMembers('lovelive/nijigaku')[memberId - 201];
        }
        if (memberId > 100) {
            return this.listMembers('lovelive/aqours')[memberId - 101];
        }
        return this.listMembers('lovelive/muse')[memberId - 1];
    }

    getASMemberIDFromName(memberName) {
        return this.listASMemberIDs().find((mid) => {
            return this.getMemberFromASID(mid).name === memberName;
        });
    }

    getStories(lang, type) {
        return this.data['stories_' + lang][type];
    }

    getMainStoryChapters(lang) {
        return this.getStories(lang, 'main');
    }

    getMainStory(lang, chapter, number) {
        return this.getMainStoryChapters(lang)[chapter].stories[number];
    }

    getSideStories(lang) {
        return this.getStories(lang, 'side');
    }

    getSideStory(lang, memberId, cardId, number) {
        return this.getSideStories(lang)[memberId][cardId][number];
    }

    getMemberStories(lang) {
        return this.getStories(lang, 'member');
    }

    getMemberStory(lang, memberId, number) {
        return this.getMemberStories(lang)[memberId][number];
    }

    getMainChapterThumbnailPath(chapter) {
        return getImageEndpoint(`story_thumbs/main/chapter_${chapter}_head`, 'jpg');
    }

    getMainStoryThumbnailPath(chapter, number) {
        return getImageEndpoint(`story_thumbs/main/chapter_${chapter}_${number}`, 'jpg');
    }

    getSideStoryThumbnailPath(memberId, cardId, number) {
        return getImageEndpoint(`story_thumbs/side/${memberId}/${cardId}_${number}`, 'jpg');
    }

    getMemberStoryThumbnailPath(memberId, number) {
        return getImageEndpoint(`story_thumbs/member/${memberId}_${number}`, 'jpg');
    }

    /**
     * Request data to be loaded
     * @param {[string]} dataReqs The data IDs to request
     * @param {function} callback The callback function when the data is finished loading
     */
    requestData(dataReqs, callback) {
        var toFetch = [];
        for (let req of dataReqs) {
            if (!this.initialized[req]) {
                toFetch.push(req);
            }
        }

        if (toFetch.length > 0) {
            var promises = toFetch.map(
                (req) =>
                    new Promise((resolve, reject) => {
                        fetchJson(req)
                            .then((resp) => {
                                if (resp.status === 200) {
                                    return resp.json();
                                }
                                return null;
                            })
                            .then((json) => {
                                this.data[req] = json;
                                resolve(json);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
            );
            Promise.all(promises)
                .then(() => {
                    if (callback) callback();
                })
                .catch((e) => console.log('Error fetching data', e));
        } else {
            callback();
        }
    }

    // // promise + set this.data for given request, recurses if necessary
    // _fetchData(req) {
    //     console.log('Fetching ' + req + '...');
    //     return new Promise((resolve, reject) => {
    //         var promise = undefined;
    //         var indexPrefix = undefined;
    //         var reqArg = req.split('/')[0];
    //         if (DATA_GROUPS.includes(reqArg)) {
    //             promise = fetchJson(req);
    //         } else if (INDEX_MAPPINGS[req] !== undefined) {
    //             promise = fetchJson(INDEX_MAPPINGS[req] + '/index');
    //             indexPrefix = INDEX_MAPPINGS[req];
    //         }
    //         if (promise) {
    //             promise
    //                 .then((resp) => {
    //                     if (resp.status === 200) {
    //                         resp
    //                             .json()
    //                             .then((val) => {
    //                                 if (indexPrefix !== undefined) {
    //                                     var indexPromises = [];
    //                                     for (let i = 0; i < val.length; i++) {
    //                                         var newReq = indexPrefix + '/' + val[i];
    //                                         indexPromises.push(this._fetchData(newReq));
    //                                     }
    //                                     Promise.all(indexPromises)
    //                                         .then((vals) => {
    //                                             resolve(vals);
    //                                             this.initialized[req] = true;
    //                                         })
    //                                         .catch(() => reject());
    //                                 } else {
    //                                     this.initialized[req] = true;
    //                                     this.data[req] = val;
    //                                     resolve(val);
    //                                 }
    //                             })
    //                             .catch(() => reject());
    //                     } else {
    //                         reject();
    //                     }
    //                 })
    //                 .catch(() => reject());
    //         } else {
    //             resolve(null);
    //         }
    //     });
    // }
}

export default new DataHandler();
