import { Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import DataHandler from '../../util/DataHandler';

const StoryCard = (props) => {
    var title = props.cardTitle ? props.cardTitle : props.story.title;
    var subtitle = props.cardTitle ? props.story.title : undefined;
    return (
        <Grid item xs={12} sm={6} md={props.smallerWidth ? 3 : 4}>
            <Link to={props.storyUrlBase + '/view'} style={{ textDecoration: 'none' }}>
                <Card variant="outlined">
                    <CardHeader title={title} subheader={subtitle} />
                    <CardMedia src={props.thumbnailUrl} component="img" />
                    <CardContent>
                        <Typography>{props.story.description}</Typography>
                    </CardContent>
                </Card>
            </Link>
        </Grid>
    );
};

const StorySectionList = (props) => {
    let cards = [];
    let stories = [];
    let currentUrl = `/stories/${props.lang}/${props.storyType}/${props.selector}`;
    var mid;
    switch (props.storyType) {
        case 'main':
            let chapterId = props.selector;
            stories = DataHandler.getMainStoryChapters(props.lang)[chapterId].stories;
            for (let storyId in stories) {
                let story = stories[storyId];
                let thumbnailUrl = DataHandler.getMainStoryThumbnailPath(chapterId, storyId);
                let cardTitle = {
                    en: `Story ${storyId}`,
                    jp: `第${storyId}話`
                }[props.lang];
                let storyUrlBase = currentUrl + '/' + storyId;

                cards.push(
                    <StoryCard
                        story={story}
                        thumbnailUrl={thumbnailUrl}
                        storyUrlBase={storyUrlBase}
                        cardTitle={cardTitle}
                        smallerWidth
                    />
                );
            }
            break;
        case 'side':
            mid = DataHandler.getASMemberIDFromName(props.selector);
            stories = DataHandler.getSideStories(props.lang)[mid];
            for (let cardId in stories) {
                let cardStories = stories[cardId];
                for (let storyNo in cardStories) {
                    let story = cardStories[storyNo];
                    let thumbnailUrl = DataHandler.getSideStoryThumbnailPath(mid, cardId, storyNo);
                    let storyUrlBase = currentUrl + '/' + cardId + '/' + storyNo;

                    cards.push(<StoryCard story={story} thumbnailUrl={thumbnailUrl} storyUrlBase={storyUrlBase} />);
                }
            }
            break;
        case 'member':
            mid = DataHandler.getASMemberIDFromName(props.selector);
            stories = DataHandler.getMemberStories(props.lang)[mid];
            for (let storyId in stories) {
                let story = stories[storyId];
                let thumbnailUrl = DataHandler.getMemberStoryThumbnailPath(mid, storyId);
                let cardTitle = '#' + storyId;
                let storyUrlBase = currentUrl + '/' + storyId;

                cards.push(
                    <StoryCard
                        story={story}
                        thumbnailUrl={thumbnailUrl}
                        storyUrlBase={storyUrlBase}
                        cardTitle={cardTitle}
                    />
                );
            }
            break;
        default:
            return <Redirect to={'/stories/' + props.lang} />;
    }
    return (
        <Grid container justify="flex-start" alignItems="stretch" spacing={1}>
            {cards.map((card, i) => <React.Fragment key={i}>{card}</React.Fragment>)}
        </Grid>
    );
};

export default StorySectionList;
