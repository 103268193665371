import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div style={{ minHeight: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'block', maxWidth: '80%', width: '800px', textAlign: 'center' }}>
                <h1 style={{ fontFamily: 'Satisfy', fontSize: '60px', margin: 0 }}>idols.snep.pw</h1>
                <div style={{ marginTop: '30px' }}>
                    <Button
                        component={Link}
                        to="/sorter"
                        variant="contained"
                        color="default"
                        style={{ padding: '20px', width: '30%', margin: '10px' }}
                    >
                        Idol Sorter
                        <br />
                        アイドルソーター
                    </Button>
                    <Button
                        component={Link}
                        to="/builder"
                        variant="contained"
                        color="default"
                        style={{ padding: '20px', width: '30%', margin: '10px', display: 'none' }}
                    >
                        SIFAS Team Builder
                    </Button>
                    <Button
                        component={Link}
                        to="/stories"
                        variant="contained"
                        color="default"
                        style={{ padding: '20px', width: '30%', margin: '10px' }}
                    >
                        SIFAS Stories
                        <br />
                        スクスタのストーリー
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Home;
