import { Button, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import HeaderBar from '../components/HeaderBar';
import StorySectionList from '../components/storyselector/StorySectionList';
import StorySectionView from '../components/storyselector/StorySectionView';
import DataHandler from '../util/DataHandler';

const StorySelector = (props) => {
    var params = useParams();

    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        DataHandler.requestData([ 'franchises', 'stories_en', 'stories_jp' ], () => {
            setLoading(false);
        });
    }, []);

    const getListName = (storyType, selector) => {
        if (params.lang === 'en') {
            switch (storyType) {
                case 'main':
                    return 'Chapter ' + selector + ' Stories';
                case 'side':
                case 'member':
                    let memId = DataHandler.getASMemberIDFromName(selector);
                    let memberInfo = DataHandler.getMemberFromASID(memId);
                    return memberInfo['label-en'] + (storyType === 'member' ? ' Bond Stories' : ' Side Stories');
                default:
                    return 'Stories';
            }
        } else if (params.lang === 'jp') {
            switch (storyType) {
                case 'main':
                    return `第${selector}章`;
                case 'side':
                case 'member':
                    let memId = DataHandler.getASMemberIDFromName(selector);
                    let memberInfo = DataHandler.getMemberFromASID(memId);
                    return memberInfo['label-jp'] + (storyType === 'member' ? 'のキズナエピソード一覧' : 'のサイドエピソード一覧');
                default:
                    return 'Stories';
            }
        }
        return <Redirect to={'/stories'} />;
    };

    return (
        <React.Fragment>
            <HeaderBar />
            <div style={{ minHeight: '70%', display: 'flex' }}>
                <div style={{ margin: 'auto', maxWidth: '95%' }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Paper
                            style={{
                                display: 'block',
                                width: '1200px',
                                maxWidth: '100%',
                                textAlign: 'center',
                                padding: '10px',
                                margin: '20px 0px'
                            }}
                        >
                            <div style={{ margin: '20px' }}>
                                <Typography variant="h2">SIFAS Stories</Typography>
                            </div>

                            {params.selector ? (
                                <div>
                                    <div>
                                        <Typography variant="h3">
                                            {getListName(params.storytype, params.selector)}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button
                                            component={Link}
                                            to={'/stories/' + params.lang + '/' + params.storytype}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            {{ jp: '戻る', en: 'Back' }[params.lang]}
                                        </Button>
                                    </div>
                                    <div style={{ margin: '10px' }}>
                                        <StorySectionList
                                            lang={params.lang}
                                            storyType={params.storytype}
                                            selector={params.selector}
                                        />
                                    </div>
                                </div>
                            ) : params.storytype ? (
                                <div>
                                    <div>
                                        <Typography variant="h3">
                                            {
                                                {
                                                    en: {
                                                        main: 'Main Stories',
                                                        side: 'Side Stories',
                                                        member: 'Member Stories'
                                                    }[params.storytype],
                                                    jp: {
                                                        main: 'ストーリー',
                                                        side: 'サイドエピソード一覧',
                                                        member: 'キズナエピソード一覧'
                                                    }[params.storytype]
                                                }[params.lang]
                                            }
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button component={Link} to={'/stories'} variant="contained" color="secondary">
                                            {{ jp: '戻る', en: 'Back' }[params.lang]}
                                        </Button>
                                    </div>
                                    <div style={{ margin: '10px' }}>
                                        <StorySectionView lang={params.lang} storyType={params.storytype} />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        <Typography variant="h4">日本語</Typography>
                                        <div>
                                            <Button
                                                component={Link}
                                                to={'/stories/jp/main'}
                                                variant="contained"
                                                style={{ padding: '10px', margin: '10px' }}
                                            >
                                                ストーリー
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                component={Link}
                                                to={'/stories/jp/side'}
                                                variant="contained"
                                                style={{ padding: '10px', margin: '10px' }}
                                            >
                                                サイドエピソード一覧
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                component={Link}
                                                to={'/stories/jp/member'}
                                                variant="contained"
                                                style={{ padding: '10px', margin: '10px' }}
                                            >
                                                キズナエピソード一覧
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography variant="h4">English Stories</Typography>
                                        <div>
                                            <Button
                                                component={Link}
                                                to={'/stories/en/main'}
                                                variant="contained"
                                                style={{ padding: '10px', margin: '10px' }}
                                            >
                                                Main Story
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                component={Link}
                                                to={'/stories/en/side'}
                                                variant="contained"
                                                style={{ padding: '10px', margin: '10px' }}
                                            >
                                                Side Stories
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                component={Link}
                                                to={'/stories/en/member'}
                                                variant="contained"
                                                style={{ padding: '10px', margin: '10px' }}
                                            >
                                                Bond Stories
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Paper>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StorySelector;
