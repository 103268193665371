import React from 'react';
import DataHandler from '../../util/DataHandler';
import { makeStyles } from '@material-ui/core';
import CardThumb from './CardThumb';

function CardDisplay(props) {
    var cardId = props.cardId;
    var subunit = props.subunit;
    var idolized = props.idolized || false;
    var setMemberCallback = props.setMemberCallback;

    const onDrop = (e) => {
        var nmid = e.dataTransfer.getData('text/x-cid');
        setMemberCallback(nmid);
    };

    const attribStyles = makeStyles({
        appeal: {
            backgroundColor: '#b0b'
        },
        stamina: {
            backgroundColor: '#090'
        },
        technique: {
            backgroundColor: '#aa0'
        }
    })();

    const subunitStyles = makeStyles({
        green: {
            backgroundColor: '#0f0'
        },
        red: {
            backgroundColor: '#f00'
        },
        blue: {
            backgroundColor: '#00f'
        }
    })();

    const subunitMap = { 0: 'green', 1: 'red', 2: 'blue' };

    return (
        <div
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            style={{
                paddingTop: '2px',
                margin: '2px auto',
                border: '2px solid #555',
                borderRadius: '5px',
                backgroundColor: '#777'
            }}
        >
            <div
                style={{
                    margin: 'auto 2px',
                    height: '10px',
                    border: '1px solid #555',
                    borderRadius: '5px'
                }}
                className={subunitStyles[subunitMap[subunit]]}
            />
            <div style={{ margin: '5px' }}>
                <CardThumb cardId={cardId} idolized={idolized} />
            </div>
            {[ 'appeal', 'stamina', 'technique' ].map((at) => (
                <div key={at} className={attribStyles[at]}>
                    <img
                        style={{ transform: 'translateY(4px)', padding: '2px' }}
                        alt=""
                        width="16"
                        height="16"
                        src={DataHandler.getASIconPath(at)}
                    />
                    {DataHandler.getASCardStat(cardId, at)}
                </div>
            ))}
        </div>
    );
}

export default CardDisplay;
