import React from 'react';
import HeaderBar from '../components/HeaderBar';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <React.Fragment>
            <HeaderBar />
            <div style={{ display: 'flex' }}>
                <div style={{ margin: 'auto', paddingTop: '10px', textAlign: 'center' }}>
                    <Typography variant="h5">The page you are looking for could not be found.</Typography>
                    <Button style={{ margin: '10px auto' }} component={Link} to="/" variant="outlined">
                        Go to Home Page
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NotFound;
