import { Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataHandler from '../../util/DataHandler';

function MemberSorter(props) {
    var members = props.members;
    var doneCallback = useCallback(props.doneCallback, []);

    var [ choiceCount, setChoiceCount ] = useState(0);
    var [ compareLeft, setCompareLeft ] = useState(null);
    var [ compareRight, setCompareRight ] = useState(null);

    var sortVals = useRef({});
    var sortLoop = useRef({});

    const sortingStep = () => {
        var vals = sortVals.current;
        var loop = sortLoop.current;
        if (vals.size < members.length) {
            if (vals.leftStart < members.length) {
                if (loop.left === undefined || loop.right === undefined) {
                    loop.left = vals.leftStart;
                    loop.right = Math.min(vals.leftStart + vals.size, members.length);
                    loop.leftLimit = loop.right;
                    loop.rightLimit = Math.min(loop.right + vals.size, members.length);
                    loop.index = loop.left;
                }
                if (vals.buffer === undefined || vals.buffer.length === 0) {
                    vals.buffer = new Array(members.length);
                }

                if (loop.left < loop.leftLimit && loop.right < loop.rightLimit) {
                    // compare
                    var memberL = vals.sorted[loop.left];
                    var memberR = vals.sorted[loop.right];

                    setCompareLeft(memberL);
                    setCompareRight(memberR);
                    setChoiceCount((count) => count + 1);
                    return;
                }

                while (loop.left < loop.leftLimit) {
                    vals.buffer[loop.index++] = vals.sorted[loop.left++];
                }
                while (loop.right < loop.rightLimit) {
                    vals.buffer[loop.index++] = vals.sorted[loop.right++];
                }
                vals.leftStart += 2 * vals.size;
                loop.left = undefined;
                loop.right = undefined;

                sortingStep();
                return;
            }

            var buff = vals.buffer.slice();
            var sorted = vals.sorted.slice();
            vals.sorted = buff;
            vals.buffer = sorted;

            vals.size *= 2;
            vals.leftStart = 0;

            sortingStep();
            return;
        }

        doneCallback(vals.sorted);
    };
    const sortingStepCall = useCallback(sortingStep, []);

    const submitComparison = (side) => {
        var vals = sortVals.current;
        var loop = sortLoop.current;

        if (side === 'left') {
            vals.buffer[loop.index++] = vals.sorted[loop.left];
            loop.left++;
        } else if (side === 'right') {
            vals.buffer[loop.index++] = vals.sorted[loop.right];
            loop.right++;
        }

        sortingStep();
    };

    useEffect(
        () => {
            const sortingInitialize = () => {
                setChoiceCount(0);

                var memberList = props.members.slice();
                for (let i = memberList.length - 1; i > 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1));
                    [ memberList[i], memberList[j] ] = [ memberList[j], memberList[i] ];
                }
                sortVals.current.sorted = memberList;

                sortVals.current.size = 1;
                sortVals.current.leftStart = 0;

                sortingStepCall();
            };

            sortingInitialize();
        },
        [ props.members, sortingStepCall ]
    );

    if (members.length === 0 || compareLeft === null || compareRight === null) return <React.Fragment />;

    var memberL = DataHandler.getMemberInfo(compareLeft);
    var memberR = DataHandler.getMemberInfo(compareRight);

    var memberImgL = DataHandler.getMemberImage(memberL.path, 'thumb');
    var memberImgR = DataHandler.getMemberImage(memberR.path, 'thumb');

    var colorsL = DataHandler.getMemberColors(memberL.path);
    var colorsR = DataHandler.getMemberColors(memberR.path);

    return (
        <div style={{ width: '100%' }}>
            <Typography variant="caption">Choice #{choiceCount}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ marginLeft: '10%', marginRight: '5%', height: '50%', maxWidth: '30%' }}>
                    <div>
                        <img
                            key={memberImgL}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                objectFit: 'scale-down',
                                borderRadius: '10px',
                                border: '5px solid ' + colorsL.light,
                                backgroundColor: colorsL.main
                            }}
                            src={memberImgL}
                            alt={memberL['label-en']}
                        />
                    </div>
                    <div>
                        <Typography variant="caption">{memberL['label-jp']}</Typography>
                    </div>
                    <div>
                        <Typography variant="caption">{memberL['label-en']}</Typography>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button variant="outlined" onClick={() => submitComparison('left')}>
                            Select / 選択する
                        </Button>
                    </div>
                </div>
                <div style={{ maxWidth: '10%', marginTop: '15%' }}>
                    <Typography variant="h6">vs.</Typography>
                </div>
                <div style={{ marginRight: '10%', marginLeft: '5%', height: '50%', maxWidth: '30%' }}>
                    <div>
                        <img
                            key={memberImgR}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                objectFit: 'scale-down',
                                borderRadius: '10px',
                                border: '5px solid ' + colorsR.light,
                                backgroundColor: colorsR.main
                            }}
                            src={memberImgR}
                            alt={memberR['label-en']}
                        />
                    </div>
                    <div>
                        <Typography variant="caption">{memberR['label-jp']}</Typography>
                    </div>
                    <div>
                        <Typography variant="caption">{memberR['label-en']}</Typography>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button variant="outlined" onClick={() => submitComparison('right')}>
                            Select / 選択する
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberSorter;
