import { Button, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import HeaderBar from '../components/HeaderBar';
import GroupSelector from '../components/sorter/GroupSelector';
import MemberSorter from '../components/sorter/MemberSorter';
import DataHandler from '../util/DataHandler';
import DataSerializer from '../util/DataSerializer';

function Sorter() {
    const [ loading, setLoading ] = useState(true);

    const [ selectedMembers, setSelectedMembers ] = useState([]);
    const [ started, setStarted ] = useState(false);
    const [ canStart, setCanStart ] = useState(false);

    const [ resultHash, setResultHash ] = useState();

    useEffect(() => {
        DataHandler.requestData([ 'franchises' ], () => {
            setLoading(false);
        });
    }, []);

    useEffect(
        () => {
            if (Object.keys(selectedMembers).length > 0) {
                setCanStart(true);
            } else {
                setCanStart(false);
            }
        },
        [ selectedMembers ]
    );

    const onDone = (results) => {
        var resHash = DataSerializer.serialize('memberlist', results);
        setResultHash(resHash);
    };

    const onStateChange = (state) => {
        var groups = Object.keys(state).filter((k) => state[k] === true);
        var memberList = [];
        for (let key of groups) {
            memberList.push(...DataHandler.listMembers(key).map((m) => key + '/' + m.name));
        }
        setSelectedMembers(memberList);
    };

    if (resultHash) return <Redirect to={'/sorter/' + resultHash} />;

    return (
        <React.Fragment>
            <HeaderBar />
            <div style={{ minHeight: '70%', display: 'flex' }}>
                <div style={{ margin: 'auto', maxWidth: '95%' }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Paper
                            style={{
                                display: 'block',
                                width: '800px',
                                maxWidth: '100%',
                                textAlign: 'center',
                                padding: '10px',
                                margin: '20px 0px'
                            }}
                        >
                            <div style={{ margin: '20px' }}>
                                <Typography variant="h2">Idol Sorter</Typography>
                            </div>
                            {started && (
                                <div>
                                    <MemberSorter members={selectedMembers} doneCallback={onDone} />
                                    <div style={{ marginTop: '20px' }}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => void setStarted(false)}
                                        >
                                            Back / 戻る
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <div hidden={started}>
                                <div>
                                    <GroupSelector
                                        franchises={DataHandler.listFranchises()}
                                        onStateChange={onStateChange}
                                    />
                                    <Typography variant="caption">
                                        Estimated choices:{' '}
                                        {Math.floor(
                                            selectedMembers.length * Math.log2(selectedMembers.length + 0.01) * 0.7
                                        )}
                                    </Typography>
                                </div>
                                <div style={{ margin: '20px' }}>
                                    <Button
                                        disabled={!canStart}
                                        variant="contained"
                                        onClick={() => void setStarted(true)}
                                    >
                                        Start / スタート
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Sorter;
