import DataHandler from './DataHandler';

class MemberList {
    /**
     * Serialize a member list
     * @param {[]} memberList The member list
     * @returns {string} The serialized data
     */
    static serialize(memberList) {
        var members = [];
        for (let member of memberList) {
            members.push(DataHandler.getMemberInfo(member));
        }

        var maxLen = 1;

        var ids = members.map((m) => {
            var franchise = DataHandler.getFranchiseInfo(m.franchisePath);
            var group = DataHandler.getGroupInfo(m.groupPath);

            var fId = franchise.id.toString();
            var gId = group.id.toString();
            var mId = m.id.toString();

            var len = Math.max(fId.length, gId.length, mId.length);
            if (len > maxLen) maxLen = len;

            return [ fId, gId, mId ];
        });

        var memberStrings = ids.map((aIds) => {
            var [ fId, gId, mId ] = aIds;

            fId = fId.padStart(maxLen, '0');
            gId = gId.padStart(maxLen, '0');
            mId = mId.padStart(maxLen, '0');

            var r = fId + gId + mId;

            return parseInt(r).toString(36);
        });

        var enc = maxLen.toString(36) + memberStrings.join(',');
        var ret = window.btoa(enc).replace(/\//g, '_').replace(/\+/g, '-').replace(/=/g, '');
        return ret;
    }

    /**
     * Deserialize a member list
     * @param {string} serialized The serialized data
     * @returns {[]} The member list
     */
    static deserialize(serialized) {
        serialized = serialized.padEnd(Math.ceil(serialized.length / 4) * 4, '=');
        serialized = serialized.replace(/-/g, '+').replace(/_/g, '/');
        var rawData = window.atob(serialized);

        var len = parseInt(rawData[0], 36);
        rawData = rawData.substr(1);

        var memberIds = rawData.split(',');
        var ret = memberIds.map((m) => {
            var r = parseInt(m, 36).toString();
            r = r.padStart(len * 3, '0');

            var fId = parseInt(r.substr(0, len));
            var gId = parseInt(r.substr(len, len));
            var mId = parseInt(r.substr(len * 2, len));

            var fPath = DataHandler.getFranchisePathFromId(fId);
            var gPath = DataHandler.getGroupPathFromId(fPath, gId);
            var mPath = DataHandler.getMemberPathFromId(gPath, mId);
            return mPath;
        });

        return ret;
    }
}

class DataSerializer {
    /**
     * Serialize data
     * @param {('memberlist')} type The type of data to serialize
     * @param {*} data The data to serialize
     * @returns {string} The serialized data, null if not possible or type not valid
     */
    static serialize(type, data) {
        try {
            switch (type) {
                case 'memberlist':
                    return MemberList.serialize(data);
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    /**
     * Deserialize data
     * @param {('memberlist')} type The type of data to deserialize from
     * @param {string} serialized The serialized data
     * @returns {*} The deserialized data, null if not possible or type not valid
     */
    static deserialize(type, serialized) {
        try {
            switch (type) {
                case 'memberlist':
                    return MemberList.deserialize(serialized);
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        }
        return null;
    }
}
DataSerializer.MemberList = MemberList;

export default DataSerializer;
