import { Button, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SortResults from '../components/sorter/SortResults';
import DataHandler from '../util/DataHandler';
import DataSerializer from '../util/DataSerializer';
import HeaderBar from '../components/HeaderBar';

function SorterResults(props) {
    const [ loading, setLoading ] = useState(true);

    const [ results, setResults ] = useState([]);

    var params = useParams();

    useEffect(() => {
        DataHandler.requestData([ 'franchises' ], () => {
            setLoading(false);
        });
    }, []);

    useEffect(
        () => {
            if (!loading && params.serialized) {
                var res = DataSerializer.MemberList.deserialize(params.serialized);
                if (res) {
                    setResults(res);
                }
            }
        },
        [ loading, params ]
    );

    return (
        <React.Fragment>
            <HeaderBar />
            <div style={{ minHeight: '70%', display: 'flex' }}>
                <div style={{ margin: 'auto', maxWidth: '95%' }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Paper
                            style={{
                                display: 'block',
                                width: '800px',
                                maxWidth: '100%',
                                textAlign: 'center',
                                padding: '10px',
                                margin: '20px 0px'
                            }}
                        >
                            <div style={{ margin: '20px' }}>
                                <Typography variant="h2">Sort Results</Typography>
                            </div>

                            <div>
                                <SortResults results={results} />
                                <div style={{ marginTop: '20px' }}>
                                    <Button variant="outlined" color="secondary" component={Link} to="/sorter">
                                        Back / 戻る
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SorterResults;
