import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

const StoryPlayer = (props) => {
    var divRef = useRef();
    var canvasRef = useRef();
    var animationRef = useRef();

    useEffect(() => {
        const renderFrame = () => {
            var ctx = canvasRef.current.getContext('2d');
            canvasRef.current.width = divRef.current.offsetWidth;
            canvasRef.current.height = Math.floor(canvasRef.current.width / 1.7778);
            var width = canvasRef.current.width;
            var height = canvasRef.current.height;

            ctx.fillStyle = '#000';
            ctx.fillRect(0, 0, width, height);

            ctx.fillStyle = '#fff';
            ctx.font = '128px sans-serif';
            var measure = ctx.measureText('WIP');
            ctx.fillText('WIP', width / 2 - measure.width / 2, height / 2);

            animationRef.current = window.requestAnimationFrame(renderFrame);
        };

        animationRef.current = window.requestAnimationFrame(renderFrame);

        return () => {
            window.cancelAnimationFrame(animationRef.current);
        };
    }, []);

    return (
        <div ref={divRef} style={{ maxWidth: '1200px', maxHeight: '900px', margin: 'auto' }}>
            <canvas ref={canvasRef} width="100" height="70" />
        </div>
    );
};

export default StoryPlayer;
