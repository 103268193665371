import { Tooltip } from '@material-ui/core';
import React from 'react';
import DataHandler from '../../util/DataHandler';

function CardThumb(props) {
    var cardId = props.cardId;
    var idolized = props.idolized || false;

    var card = DataHandler.getASCard(cardId);

    return (
        <span
            style={{ margin: '2px' }}
            draggable
            onDragStart={(e) => {
                e.dataTransfer.setData('text/x-cid', cardId);
            }}
        >
            <Tooltip
                title={
                    <React.Fragment>
                        <div>
                            No. {card.no} - {idolized ? card.name_idolized : card.name_unidolized}
                        </div>
                        <div>
                            {[ 'appeal', 'stamina', 'technique' ].map((at) => (
                                <span key={at} style={{ padding: '2px' }}>
                                    <img
                                        style={{ transform: 'translateY(4px)', padding: '2px' }}
                                        alt=""
                                        width="16"
                                        height="16"
                                        src={DataHandler.getASIconPath(at)}
                                    />
                                    {DataHandler.getASCardStat(card.id, at)}
                                </span>
                            ))}
                        </div>
                    </React.Fragment>
                }
                placement="right"
                TransitionComponent={(p) => p.children}
            >
                <img width="64" height="64" alt="" src={DataHandler.getASCardIconPath(card.id, idolized)} />
            </Tooltip>
        </span>
    );
}

export default CardThumb;
