import { Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import DataHandler from '../../util/DataHandler';

const ChapterCard = (props) => {
    return (
        <Grid item xs={12} sm={6} md={3}>
            <Link to={'/stories/' + props.lang + '/main/' + props.chapterId} style={{ textDecoration: 'none' }}>
                <Card variant="outlined">
                    <CardHeader title={props.chapter.title} />
                    <CardMedia src={DataHandler.getMainChapterThumbnailPath(props.chapterId)} component="img" />
                    <CardContent style={{ minHeight: '80px' }}>
                        <Typography>{props.chapter.description}</Typography>
                    </CardContent>
                </Card>
            </Link>
        </Grid>
    );
};

const MemberCard = (props) => {
    return (
        <Grid item xs={4} sm={3} md={2}>
            <Link
                to={'/stories/' + props.lang + '/' + props.storyType + '/' + props.member.name}
                style={{ textDecoration: 'none' }}
            >
                <Card
                    variant="outlined"
                    style={{
                        borderWidth: '3px',
                        borderColor: props.member.colors.light
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        style={{
                            backgroundColor: props.member.colors.dark
                        }}
                    >
                        <CardMedia src={DataHandler.getMemberImage(props.member.path, 'thumb')} component="img" />
                        <CardContent style={{ margin: 'auto', textAlign: 'center' }}>
                            <Typography>{props.member['label-' + props.lang]}</Typography>
                        </CardContent>
                    </Grid>
                </Card>
            </Link>
        </Grid>
    );
};

const StorySectionView = (props) => {
    let cards = [];
    switch (props.storyType) {
        case 'main':
            let chapters = DataHandler.getMainStoryChapters(props.lang);
            for (let chapterId in chapters) {
                let chapter = chapters[chapterId];

                cards.push(<ChapterCard lang={props.lang} chapterId={chapterId} chapter={chapter} />);
            }
            break;
        case 'side':
            for (let memberId of DataHandler.listASMemberIDs()) {
                let member = DataHandler.getMemberFromASID(memberId);

                cards.push(<MemberCard lang={props.lang} memberId={memberId} member={member} storyType="side" />);
            }
            break;
        case 'member':
            for (let memberId of DataHandler.listASMemberIDs()) {
                let member = DataHandler.getMemberFromASID(memberId);

                cards.push(<MemberCard lang={props.lang} memberId={memberId} member={member} storyType="member" />);
            }
            break;
        default:
            return <Redirect to={'/stories'} />;
    }
    return (
        <Grid container justify="flex-start" alignItems="stretch" spacing={1}>
            {cards.map((card, i) => <React.Fragment key={i}>{card}</React.Fragment>)}
        </Grid>
    );
};

export default StorySectionView;
