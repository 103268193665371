import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Sorter from './pages/Sorter';
import SorterResults from './pages/SorterResults';
import StorySelector from './pages/StorySelector';
import StoryView from './pages/StoryView';
import TeamBuilder from './pages/TeamBuilder';

function AppRouter() {
    const theme = createMuiTheme({
        palette: {
            type: 'dark'
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/builder" component={TeamBuilder} />
                    <Route path="/sorter/:serialized" component={SorterResults} />
                    <Route path="/sorter" component={Sorter} />
                    <Route path="/stories/:lang(en|jp)/:storytype/:selector/:storytoview+" component={StoryView} />
                    <Route path="/stories/:lang(en|jp)/:storytype/:selector" component={StorySelector} />
                    <Route path="/stories/:lang(en|jp)/:storytype" component={StorySelector} />
                    <Route path="/stories" component={StorySelector} />
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default AppRouter;
