import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';

function GroupSelector(props) {
    var onStateChange = useCallback(props.onStateChange, []);

    const [ selectState, setSelectState ] = useState({});

    const handleChange = (e) => {
        setSelectState({ ...selectState, [e.target.name]: e.target.checked });
    };

    useEffect(
        () => {
            if (selectState && onStateChange) {
                onStateChange(selectState);
            }
        },
        [ selectState, onStateChange ]
    );

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {props.franchises.map((f) => (
                <FormControl
                    key={f.name}
                    style={{ margin: '0 10px', padding: '5px 20px', border: '2px solid #fff', borderRadius: '5px' }}
                >
                    <div>
                        <Typography variant="h6">{f['label-jp']}</Typography>
                    </div>
                    <div>
                        <Typography variant="h6">{f['label-en']}</Typography>
                    </div>
                    <FormGroup>
                        {f.groups.map((g) => (
                            <FormControlLabel
                                key={g.name}
                                control={<Checkbox color="primary" />}
                                onChange={handleChange}
                                name={f.name + '/' + g.name}
                                label={
                                    g['label-en'] !== g['label-jp'] ? (
                                        g['label-jp'] + ' / ' + g['label-en']
                                    ) : (
                                        g['label-jp']
                                    )
                                }
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            ))}
        </div>
    );
}

export default GroupSelector;
