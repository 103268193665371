import { Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CardDisplay from '../components/builder/CardDisplay';
import CardThumb from '../components/builder/CardThumb';
import DataHandler from '../util/DataHandler';
import HeaderBar from '../components/HeaderBar';

function TeamBuilder() {
    const [ loading, setLoading ] = useState(true);
    const [ selTeam, setSelTeam ] = useState([
        '102081001',
        '102061001',
        '102041001',
        '102021001',
        '102011001',
        '102031001',
        '102051001',
        '102071001',
        '102091001'
    ]);
    const [ subunits, setSubunits ] = useState([ 1, 1, 1, 0, 0, 0, 2, 2, 2 ]); // eslint-disable-line no-unused-vars

    useEffect(() => {
        DataHandler.requestData([ 'card_info', 'game_info' ], () => {
            setLoading(false);
        });
    }, []);

    const setMemberToSlot = (slot, member) => {
        // swap members if {member} exists already
        for (let i in selTeam) {
            let mid = selTeam[i];
            if (i !== slot && mid === member) {
                setSelTeam((t) => {
                    let m = t.slice();
                    m[i] = m[slot];
                    return m;
                });
            }
        }
        setSelTeam((t) => {
            let m = t.slice();
            m[slot] = member;
            return m;
        });
    };

    return (
        <React.Fragment>
            <HeaderBar />
            <div style={{ height: '80%', display: 'flex' }}>
                <div style={{ margin: 'auto', maxWidth: '95%' }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Paper
                            style={{
                                display: 'block',
                                width: '1000px',
                                maxWidth: '100%',
                                textAlign: 'center',
                                padding: '10px',
                                margin: '20px 0px'
                            }}
                        >
                            <div style={{ margin: '20px' }}>
                                <Typography variant="h2">SIFAS Team Builder</Typography>

                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    {[ 0, 1, 2, 3, 4, 5, 6, 7, 8 ].map((i) => (
                                        <CardDisplay
                                            setMemberCallback={(nmid) => setMemberToSlot(i, nmid)}
                                            cardId={selTeam[i]}
                                            subunit={subunits[i]}
                                            idolized
                                        />
                                    ))}
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <span>Total: </span>
                                    {[ 'appeal', 'stamina', 'technique' ].map((at) => {
                                        var total = 0;
                                        for (let mid of selTeam) {
                                            total += DataHandler.getASCardStat(mid, at);
                                        }
                                        return (
                                            <span key={at} style={{ padding: '2px' }}>
                                                <img
                                                    style={{ transform: 'translateY(7px)', padding: '4px' }}
                                                    alt=""
                                                    width="24"
                                                    height="24"
                                                    src={DataHandler.getASIconPath(at)}
                                                />
                                                {total}
                                            </span>
                                        );
                                    })}
                                </div>
                                <div hidden={loading} style={{ overflowY: 'scroll', height: '500px' }}>
                                    {DataHandler.listASCards().sort((a, b) => a.no - b.no).map((card, i) => {
                                        return <CardThumb key={i} cardId={card.id} idolized />;
                                    })}
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default TeamBuilder;
