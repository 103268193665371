import { Typography } from '@material-ui/core';
import React from 'react';
import DataHandler from '../../util/DataHandler';

function SortResults(props) {
    var results = props.results;

    var members = results.map((m) => DataHandler.getMemberInfo(m));

    return (
        <div>
            <Typography variant="h5">Results:</Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <table>
                    <tbody>
                        {members.map((member, i) => (
                            <tr key={i}>
                                <td style={{ textAlign: 'right' }}>
                                    <Typography style={{ display: 'inline-block' }}>{i + 1}.</Typography>
                                </td>
                                <td>
                                    <img
                                        style={{
                                            display: 'inline-block',
                                            backgroundColor: DataHandler.getMemberColor(member.path, 'main'),
                                            border: '2px solid ' + DataHandler.getMemberColor(member.path, 'light'),
                                            borderRadius: 16
                                        }}
                                        width="48"
                                        height="48"
                                        src={DataHandler.getMemberImage(member.path, 'thumb')}
                                        alt=""
                                    />
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <div>
                                        <Typography style={{ display: 'inline-block' }} variant="body1">
                                            {member['label-jp']}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ display: 'inline-block' }} variant="body1">
                                            {member['label-en']}
                                        </Typography>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SortResults;
