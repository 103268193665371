import { Button, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect, Link, useParams } from 'react-router-dom';
import DataHandler from '../util/DataHandler';
import StoryPlayer from '../components/storyselector/StoryPlayer';
import HeaderBar from '../components/HeaderBar';

const StoryView = (props) => {
    var params = useParams();

    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        DataHandler.requestData([ 'franchises', 'stories_en', 'stories_jp' ], () => {
            setLoading(false);
        });
    }, []);

    const getCurrentStoryInfo = () => {
        var storyParams = params.storytoview.split('/');
        var viewParam = storyParams[storyParams.length - 1];
        if (viewParam === 'view') {
            var mid;
            switch (params.storytype) {
                case 'main':
                    return DataHandler.getMainStory(params.lang, params.selector, storyParams[0]);
                case 'side':
                    mid = DataHandler.getASMemberIDFromName(params.selector);
                    return DataHandler.getSideStory(params.lang, mid, storyParams[0], storyParams[1]);
                case 'member':
                    mid = DataHandler.getASMemberIDFromName(params.selector);
                    return DataHandler.getMemberStory(params.lang, mid, storyParams[0]);
                default:
                    return null;
            }
        }
    };

    if (!loading) {
        var currentStory = getCurrentStoryInfo();
        if (!currentStory) {
            return <Redirect to="/stories" />;
        }
    }

    return (
        <React.Fragment>
            <HeaderBar />
            <div style={{ minHeight: '70%', display: 'flex' }}>
                <div style={{ margin: 'auto', maxWidth: '95%' }}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Paper
                            style={{
                                display: 'block',
                                width: '1300px',
                                maxWidth: '100%',
                                textAlign: 'center',
                                padding: '10px',
                                margin: '20px 0px'
                            }}
                        >
                            <div style={{ margin: '20px' }}>
                                <Typography variant="h5">{currentStory.title}</Typography>
                            </div>

                            <div>
                                <div style={{ margin: '10px' }}>
                                    <StoryPlayer scriptPath={currentStory.script} />
                                </div>
                                <div>
                                    <Button
                                        component={Link}
                                        to={`/stories/${params.lang}/${params.storytype}/${params.selector}`}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StoryView;
