import React from 'react';
import { AppBar, Toolbar, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const HeaderBar = (props) => {
    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="button" style={{ flex: 1 }}>
                        idols.snep.pw
                    </Typography>
                    <Button style={{ margin: '10px' }} component={Link} to="/" variant="outlined">
                        Home / ホーム
                    </Button>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default HeaderBar;
